import React, { useState, useEffect } from 'react';

const CookieInfoBar = () => {
  const [isAccepted, setIsAccepted] = useState(false);

  // Check localStorage when the component mounts
  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookie_consent');
    if (cookieAccepted) {
      setIsAccepted(true); // Hide the bar if cookies are already accepted
    }
  }, []);

  const handleAccept = () => {
    // Set cookiesAccepted to true in localStorage
    localStorage.setItem('cookie_consent', 'true');
    setIsAccepted(true); // Hide the bar
  };

  // If cookies are already accepted, don't show the bar
  if (isAccepted) return null;

  const styles = {
    cookiesInfobar: {
      backgroundColor: '#f8f9fa',

      border: '1px solid #dee2e6',
      position: 'fixed',
      bottom: '0',
      left: '0',
      right: '0',
      zIndex: '9999', // Ensures it's on top of all components
      display: 'flex',
      justifyContent: 'center',
    },
    contentWrapper: {
      width: '70%', // Set the width to 70% of the window
      maxWidth: '1200px', // Limit the maximum width for larger screens
      margin: '0 auto', // Center the content
    },
    textLeft: {
      textAlign: 'left',
    },
    fontWeightBold: {
      fontWeight: 'bold',
    },
    f15: {
      fontSize: '15px',
    },
    f18: {
      fontSize: '18px',
    },
    cookiesInfobarWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
    },
    customBtn: {
      backgroundColor: '#007bff',
      color: 'white',
      padding: '10px 15px',
      textDecoration: 'none',
      borderRadius: '5px',
      border: 'none',
      cursor: 'pointer',
      marginBottom: '15px',
      marginRight: '15px',
    },
    customBtnHover: {
      backgroundColor: '#0056b3',
    },
    textWhiteBtn: {
      color: 'white',
    },
  };

  return (
    <div style={styles.cookiesInfobar}>
      <div style={styles.contentWrapper}>
        <p style={{ ...styles.textLeft, ...styles.fontWeightBold }}>
          เว็บไซต์นี้ใช้คุกกี้
        </p>
        <p style={{ ...styles.textLeft, ...styles.f15 }}>
          สำนักงาน กสทช. มีการใช้งานคุกกี้เพื่อให้ผู้เข้าชมเว็บไซต์ได้รับความสะดวกสบาย ได้รับการนำเสนอเนื้อหาที่เหมาะสม รวมถึงเพื่อการสนับสนุนความปลอดภัย การวิเคราะห์การทำงานของเว็บไซต์เพื่อพัฒนาบริการของสำนักงาน กสทช. ให้ดียิ่งขึ้น ผู้เข้าชมเว็บไซต์สามารถเลือก กดปุ่ม "ยอมรับ" เพื่ออนุญาตให้ใช้คุกกี้ทุกประเภท ทั้งนี้ สำนักงาน กสทช. ขอเรียนให้ผู้เข้าชมเว็บไซต์ทราบว่าหากท่านไม่ยอมรับการใช้งานคุกกี้ การนำเสนอเนื้อหาและการใช้งานเว็บไซต์จะไม่ได้รับความสะดวกสบายในการใช้บริการ โดยผู้เข้าชมสามารถศึกษารายละเอียดเพิ่มเติมได้
        </p>
        <div style={styles.cookiesInfobarWrapper}>

          <a
            href="https://www.nbtc.go.th/getattachment/451faeb5-9caf-4af9-a591-9b86097a20ec/Cookies-Notice.aspx"
            target="_blank"
            rel="noopener noreferrer"
            style={styles.customBtn}
          >
            นโยบายคุกกี้
          </a>

          <button
            style={styles.customBtn}
            onClick={handleAccept}
            onMouseOver={(e) => (e.target.style.backgroundColor = '#0056b3')}
            onMouseOut={(e) => (e.target.style.backgroundColor = '#007bff')}
          >
            ยอมรับ
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieInfoBar;
