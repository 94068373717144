import { fromUnixTime } from 'date-fns'
import API from '../config/axios'
import IBMAPI from '../config/axiosIBM'
const sDOCAPI = {
	getLists: isShowFinished => {
		return API.get('/SDoc?isShowFinished=' + isShowFinished)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	getAllLists: () => {
		return API.get('/SDoc/All')
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	getListsApproved: () => {
		return API.get('/SDoc/Approved')
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	getListsV2: poa => {
		return API.get('/SDoc/v2?poa=' + poa)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	getListDetail: id => {
		return API.get(`/SDoc/${id}`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	getListsStatus: () => {
		return API.get('/SDoc/Status')
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	getPreviewSDoC: (brandName, productModel, productDescription, owner, items, title) => {
		return API.post('/Document/PreviewSDoC/', {
			brandName,
			productModel,
			productDescription,
			owner,
			items,
			title,
		})
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err.response
			})
	},
	addList: (brandName, productModel, productDescription, owner, items) => {
		return IBMAPI.post('/SDoc', {
			brandName,
			productModel,
			productDescription,
			owner,
			items,
		})
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err.response
			})
	},
	editList: (id, brandName, productModel, productDescription, owner, items) => {
		return IBMAPI.post(`/SDoc/${id}`, {
			brandName,
			productModel,
			productDescription,
			owner,
			items,
		})
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err.response
			})
	},
	attachAdditionalFiles: async (sDOCID, files, onUploadProgress) => {
		const CHUNK_SIZE = 1024 * 1024 * 2; // 2MB chunks, adjust as needed
		const totalFiles = files.length;
		let totalSize = files.reduce((total, file) => total + file.file.size, 0);
		let totalUploaded = 0;
		let fileIndex = 0;
		let currentChunk = 0;
		let Response = null;
	
		const uploadChunk = async (file, currentChunk, randomSuffix) => {
			const start = file.chunkStart || 0;
			const end = Math.min(start + CHUNK_SIZE, file.file.size);
			const chunk = file.file.slice(start, end);
			const fileExtension = file.file.name.split('.').pop();
			const originalFileName = file.file.name.split('.').slice(0, -1).join('.');
			const modifiedFileName = `${originalFileName}_Rnd${randomSuffix}_File${fileIndex}.${fileExtension}`;
	
			const formData = new FormData();
			formData.append('file', chunk);
			formData.append('title', file.name);
			formData.append('originalFileName', modifiedFileName);
			formData.append('chunkNumber', currentChunk);
			formData.append('totalChunks', Math.ceil(file.file.size / CHUNK_SIZE));
	
			const config = {
				headers: {
					'Content-Type': 'multipart/form-data',
				},
				onUploadProgress: (progressEvent) => {
					const percentCompleted = Math.round((totalUploaded + progressEvent.loaded) * 100 / totalSize);
					onUploadProgress(percentCompleted);
				},
			};
	
			try {
				const response = await IBMAPI.post(`/SDoc/${sDOCID}/attach`, formData, config);
				file.chunkStart = end; // Move chunk start point for the next chunk
				totalUploaded += end - start;
				Response = response;
	
				if (end < file.file.size) {
					// Upload next chunk
					await uploadChunk(file, currentChunk + 1, randomSuffix);
				}
			} catch (error) {
				console.error(error);
				throw error;
			}
		};
	
		const uploadNextFile = async () => {
			while (fileIndex < totalFiles) {
				const randomSuffix = Math.floor(Math.random() * 10000); // Generate once per file
				currentChunk = 0;
				files[fileIndex].chunkStart = 0; // Reset chunkStart for each file
				await uploadChunk(files[fileIndex], currentChunk, randomSuffix); // Wait for the file to finish uploading
				fileIndex++;
			}
		};
	
		try {
			// Start uploading the files
			await uploadNextFile();
			console.log('All files and chunks have been uploaded.');
			console.log('Responses:', Response);
			return Response;
		} catch (error) {
			console.error('Error during file upload:', error);
			return error.response;
		}
	},	

	deleteAdditionalFile: (sDOCID, fileId) => {
		return API.delete(`/SDoc/${sDOCID}/attach/${fileId}`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			}
			)
	},
	downloadAdditionalFile: (sDOCId, fileId) => {
		return API.get(`/SDoc/${sDOCId}/attach/${fileId}/download`)
			.then(res => res)
			.catch(err => err)
	},
	billInquiry: (sDocID) => {
		return API.put(`/SDoc/${sDocID}/billinquiry`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	billRenew: (sDocID) => {
		return API.put(`/SDoc/${sDocID}/renewbill`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
	cancelSDoC: (sDocID) => {
		return IBMAPI.put(`/SDoc/${sDocID}/cancelSDoC`)
			.then(res => res)
			.catch(err => {
				console.error(err)
				return err
			})
	},
}

export default sDOCAPI
